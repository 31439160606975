@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    .grecaptcha-badge {
        opacity: 0;
    }
    overflow-x: hidden;
}

/**
  * Fonts
  */

@font-face {
    font-family: "nexa-bold";
    src: url("/fonts/NexaBold.otf");
}

@font-face {
    font-family: "nexa-light";
    src: url("/fonts/NexaLight.otf");
}

body {
    overflow-x: hidden;
}

html {
    font-family: "nexa-light";
}

/**
  * Layer base
  */
@layer base {
    .font-outline-1 {
        -webkit-text-stroke: 1px white;
    }
    .font-outline-4 {
        -webkit-text-stroke: 4px white;
    }
    .scroll-snap-container {
        scroll-snap-type: y mandatory;
        overflow-y: scroll;
        scroll-behavior: smooth;
        scrollbar-width: none;
        height: 100vh;
        width: 100%;
    }

    .snap-start {
        scroll-snap-align: start;
    }

    .bg-header {
        background: rgba(30, 30, 30, 0.6);
        backdrop-filter: blur(15px);
        border-radius: 20px;
    }

    .bg-dropdown {
        background: rgb(30, 30, 30);
        /* backdrop-filter: blur(5px); */
        border-radius: 20px;
    }

    /* Famous */

    .bg-famous {
        border-radius: 0px 0px 0px 65px;
        background: linear-gradient(38deg, #e5aa12 0%, #ffdd17 100%);
    }

    .bg-benefits-1 {
        border-radius: 30px;
        background: linear-gradient(207deg, #f24c43 0%, #8a140d 79.41%);
    }

    .bg-about {
        background: linear-gradient(252.44deg, #612da4 0%, #300850 100%);
    }

    .background-animate {
        background-size: 400%;

        -webkit-animation: AnimationName 5s ease infinite;
        -moz-animation: AnimationName 5s ease infinite;
        animation: AnimationName 5s ease infinite;
    }

    .background-animate2 {
        background-size: 400%;

        -webkit-animation: AnimationName 10s ease infinite;
        -moz-animation: AnimationName 5s ease infinite;
        animation: AnimationName 5s ease infinite;
    }

    @keyframes AnimationName {
        0%,
        100% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
    }

    @keyframes AnimationName2 {
        0%,
        100% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
    }
  :root {
        --background: 0 0% 100%;
        --foreground: 240 10% 3.9%;
        --card: 0 0% 100%;
        --card-foreground: 240 10% 3.9%;
        --popover: 0 0% 100%;
        --popover-foreground: 240 10% 3.9%;
        --primary: 240 5.9% 10%;
        --primary-foreground: 0 0% 98%;
        --secondary: 240 4.8% 95.9%;
        --secondary-foreground: 240 5.9% 10%;
        --muted: 240 4.8% 95.9%;
        --muted-foreground: 240 3.8% 46.1%;
        --accent: 240 4.8% 95.9%;
        --accent-foreground: 240 5.9% 10%;
        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 0 0% 98%;
        --border: 240 5.9% 90%;
        --input: 240 5.9% 90%;
        --ring: 240 10% 3.9%;
        --chart-1: 12 76% 61%;
        --chart-2: 173 58% 39%;
        --chart-3: 197 37% 24%;
        --chart-4: 43 74% 66%;
        --chart-5: 27 87% 67%;
        --radius: 0.5rem;
        --sidebar-background: 0 0% 98%;
        --sidebar-foreground: 240 5.3% 26.1%;
        --sidebar-primary: 240 5.9% 10%;
        --sidebar-primary-foreground: 0 0% 98%;
        --sidebar-accent: 240 4.8% 95.9%;
        --sidebar-accent-foreground: 240 5.9% 10%;
        --sidebar-border: 220 13% 91%;
        --sidebar-ring: 217.2 91.2% 59.8%;
    }
  .dark {
        --background: 240 10% 3.9%;
        --foreground: 0 0% 98%;
        --card: 240 10% 3.9%;
        --card-foreground: 0 0% 98%;
        --popover: 240 10% 3.9%;
        --popover-foreground: 0 0% 98%;
        --primary: 0 0% 98%;
        --primary-foreground: 240 5.9% 10%;
        --secondary: 240 3.7% 15.9%;
        --secondary-foreground: 0 0% 98%;
        --muted: 240 3.7% 15.9%;
        --muted-foreground: 240 5% 64.9%;
        --accent: 240 3.7% 15.9%;
        --accent-foreground: 0 0% 98%;
        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 0 0% 98%;
        --border: 240 3.7% 15.9%;
        --input: 240 3.7% 15.9%;
        --ring: 240 4.9% 83.9%;
        --chart-1: 220 70% 50%;
        --chart-2: 160 60% 45%;
        --chart-3: 30 80% 55%;
        --chart-4: 280 65% 60%;
        --chart-5: 340 75% 55%;
        --sidebar-background: 240 5.9% 10%;
        --sidebar-foreground: 240 4.8% 95.9%;
        --sidebar-primary: 224.3 76.3% 48%;
        --sidebar-primary-foreground: 0 0% 100%;
        --sidebar-accent: 240 3.7% 15.9%;
        --sidebar-accent-foreground: 240 4.8% 95.9%;
        --sidebar-border: 240 3.7% 15.9%;
        --sidebar-ring: 217.2 91.2% 59.8%;
    }
}
@layer base {
  * {
    @apply border-border;
    }
  body {
    @apply bg-background text-foreground;
    }
}
